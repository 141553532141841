import {
    CardAuthor,
    CardContainer,
    CardContent,
    CardImage,
    CardTitle,
} from './styles'
import compressedImage from '@/helpers/compressedImage'
import { setSegmentName } from '@/store/actions/common'
import Link from 'next/link'
import { useDispatch } from 'react-redux'

const CardItem = ({
    id,
    image,
    imageMeta,
    title,
    subTitle,
    videoUrl,
    author,
    entityType,
    sectionTitle,
    isPremium,
    earlyAccessFinishDate,
    badge,
}) => {
    const dispatch = useDispatch()

    const slicedTitle = (title) =>
        title.length > 55 ? title.slice(0, 55) + '...' : title

    const slicedSubTitle = (subtitle) =>
        subtitle && subtitle.length > 62
            ? subtitle.slice(0, 62) + '...'
            : subtitle

    return (
        <Link href={`/${entityType}/${id}`} prefetch={false}>
            <a onClick={() => dispatch(setSegmentName(sectionTitle))}>
                <CardContainer>
                    <CardImage
                        loading="lazy"
                        src={compressedImage(image, imageMeta)}
                        alt={title}
                    />
                    {sectionTitle === 'Top Episode' ? (
                        <>
                            {badge === 'vip' && (
                                <img
                                    src="/img//history-crown.svg"
                                    alt="vip badge"
                                    style={{
                                        position: 'absolute',
                                        bottom: '4px',
                                        left: '4px',
                                        borderRadius: '0px',
                                        maxWidth: '90px',
                                        height: '16px',
                                    }}
                                />
                            )}
                            {badge === 'earlyAccess' && (
                                <img
                                    src="/img/early_access_mobile.svg"
                                    alt="early access badge"
                                    style={{
                                        position: 'absolute',
                                        bottom: '4px',
                                        left: '4px',
                                        borderRadius: '0px',
                                        maxWidth: '90px',
                                        height: '16px',
                                    }}
                                />
                            )}
                            {badge === 'premium' && (
                                <img
                                    src="/img/crown.svg"
                                    alt="premium badge"
                                    style={{
                                        position: 'absolute',
                                        bottom: '4px',
                                        left: '4px',
                                        borderRadius: '0px',
                                        height: '16px',
                                    }}
                                />
                            )}
                        </>
                    ) : (
                        <>
                            {isPremium && (
                                <>
                                    <img
                                        src={
                                            earlyAccessFinishDate
                                                ? '/img/early_access.svg'
                                                : '/img/vip.svg'
                                        }
                                        alt="vip badge"
                                        style={{
                                            position: 'absolute',
                                            bottom: '4px',
                                            left: '4px',
                                            borderRadius: '0px',
                                            maxWidth: '90px',
                                        }}
                                    />
                                </>
                            )}
                        </>
                    )}

                    <CardContent>
                        <CardTitle>
                            {videoUrl && (
                                <img
                                    style={{
                                        width: '16px',
                                        height: '16px',
                                        marginRight: '6px',
                                        marginTop: '-2px',
                                    }}
                                    alt="video icon"
                                    src="/img/video_icon.svg"
                                />
                            )}
                            {slicedTitle(title)}
                        </CardTitle>

                        {subTitle && (
                            <CardAuthor>{slicedSubTitle(subTitle)}</CardAuthor>
                        )}
                        {author && (
                            <CardAuthor>{slicedSubTitle(author)}</CardAuthor>
                        )}
                    </CardContent>
                </CardContainer>
            </a>
        </Link>
    )
}

export default CardItem
