import { ColumnFlex, RowFlex } from '@/themes/genericStyles'
import styled from 'styled-components'

export const Container = styled.div`
    margin-top: 20px;
    margin-bottom: 30px;
    ${ColumnFlex}
`
export const ItemContainer = styled.div`
    display: grid;
    grid-template-rows: repeat(4, auto);
    grid-auto-flow: column;
    grid-gap: 18px;
    justify-content: left;
    padding: 0 20px;
    width: 100%;
    overflow: hidden;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
`
export const CardContainer = styled.div`
    cursor: pointer;
    width: 80vw;
    max-width: 350px;
    ${RowFlex}
    justify-content: flex-start;
    align-items: start;
    padding-right: 15px;
    position: relative;
    height: 100%;
`
export const CardImage = styled.img`
    border-radius: 8px;
    width: 100px;
    height: 100px;
    aspect-ratio: 1;
    object-fit: cover;
`
export const CardContent = styled.div`
    margin-left: 15px;
    margin-right: auto;
    padding-right: 10px;
    line-height: 19px;
    max-height: 120px;
    display: flex;
    flex-direction: column;
    gap: 4px;
`
export const CardTitle = styled.h2`
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;
    padding: 10px 0 0;
    letter-spacing: 0.03em;
    line-height: 20px;
    margin: 0;

    max-height: 70px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
`
export const CardAuthor = styled.span`
    font-size: 12px;
    letter-spacing: 0.03em;
    color: #707070;
    line-height: 19.2px;
    letter-spacing: 0.12px;

    max-height: 50px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
`
